import React, { useState, useMemo, useEffect, useRef } from 'react';
import PuffLoader from 'react-spinners/PuffLoader';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import Countdown from 'react-countdown';
import { toastr } from 'react-redux-toastr';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import PerkGiveaway from './PerkGiveaway';
import { activateBundle, requestTickets } from './actions';
import BundleModal from './BundleModal';
import { selectCustomerStatus, selectCustomerToken } from '../Profile/selectors';

const Bundle = ({
  setBundleExpired,
  bundleExpired,
  bundle,
  updateOffersOrReferral,
  signUp,
}) => {
  const dispatch = useDispatch();
  const [activating, setActivating] = useState(false);
  const [requesting, setRequesting] = useState(false);
  const [claiming, setClaiming] = useState(false);
  const [requested, setRequested] = useState(false);
  const [reason, setReason] = useState('no_matches');
  const [error, setError] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const history = useHistory();
  const customerToken = useSelector(selectCustomerToken);
  const customerStatus = useSelector(selectCustomerStatus);

  const useOnScreen = (ref) => {
    const [isIntersecting, setIntersecting] = useState(true);
    const observer = useMemo(
      () =>
        new IntersectionObserver(([entry]) =>
          setIntersecting(entry.isIntersecting)
        ),
      [ref]
    );

    useEffect(() => {
      if (ref.current) {
        observer.observe(ref.current);
        return () => observer.disconnect();
      }
    }, []);

    return isIntersecting;
  };

  const activate = (token) => {
    setActivating(true);
    new Promise((resolve, reject) => {
      dispatch(activateBundle(token, resolve, reject));
    })
      .then(() => {
        history.push(`/offers/${customerToken}`);
      })
      .catch(() => {
        setError(
          "Oh no! Something went wrong and it's probably our fault. Click the Button below and we'll start working on sending you another set of tickets."
        );
        setActivating(false);
      });
  };

  const ticketRequest = (token, params) => {
    setRequesting(true);
    new Promise((resolve, reject) => {
      dispatch(requestTickets(params, token, resolve, reject));
    })
      .then(() => {
        setRequested(true);
        setError(
          "We sent this to our support squad. They'll be in touch within 24 with more tix."
        );
        if (customerStatus === 'lead') {
          history.push(`/account/${customerToken}`);
          toastr.success('Success!', 'See you in 7 days!');
        } else if (params.reason === 'do_not_like') {
          toastr.success('Success!', 'See you in 7 days!');
          history.push(`/tickets/${customerToken}`);
        }
      })
      .catch(() => {
        setError(
          "Oh no! Something went wrong and it's probably our fault. Click the Button below and we'll start working on sending you another set of tickets."
        );
        setRequesting(false);
      });
  };

  const onNeedHelp = () => {
    setReason('do_not_like');
    const params = {
      message: '',
      reason: 'do_not_like',
    };
    ticketRequest(bundle.short_token, params);
  };

  const ref = useRef(null);
  const isVisible = useOnScreen(ref);

  return (
    <React.Fragment key={`bundle-${bundle.token}`}>
      {bundle.perk_giveaways.length > 0 && (
        <strong>
          {bundleExpired.includes(bundle.token) && error}
          {error && reason === 'expired' && !requested && (
            <button
              disabled={requesting}
              style={{ marginTop: '10px', marginBottom: '10px' }}
              onClick={() =>
                ticketRequest(bundle.short_token, {
                  reason,
                  message: '',
                })
              }
            >
              MORE TIX PLZ
            </button>
          )}
        </strong>
      )}
      <div
        className="grid-x grid-padding-x grid-padding-y profile-container"
        ref={ref}
      >
        <div
          className="small-12 cell float-center ds-container"
          style={{
            display: bundle.perk_giveaways.length === 0 ? 'none' : '',
            marginBottom: '20px',
          }}
        >
          <div
            className="medium-3 large-3"
            style={{ backgroundColor: '#000', padding: '10px' }}
          >
            <h3 className="sub-header" style={{ color: '#fff' }}>
              Ticket Selection
            </h3>
          </div>
          <div
            className="medium-3 large-3"
            style={{ backgroundColor: '#fff', padding: '10px' }}
          >
            <p>
              Claim your tickets to <strong>ONE</strong> of the following events. Remember, the
              tickets are FREE and you get two!
            </p>
            <Countdown
              date={moment(bundle.expires_at).toDate()}
              onComplete={() => {
                setBundleExpired((oldBundleExpired) => [
                  ...oldBundleExpired,
                  bundle.token,
                ]);
                setReason('expired');
                setError(
                  "Oh no! Time ran out. We can send you more tix if you'd like? Just click below!"
                );
              }}
              onTick={(data) => {
                if (data.minutes === 5 && data.seconds === 0) {
                  toastr.info('Less than 5 mins left to make your choice!');
                }
              }}
              renderer={(data) => (
                <strong>
                  These tickets will be held for you for the next {data.days > 0 ? `${data.days} days ` : ''}{data.hours > 0 ? `${data.hours} hours ` : ''} {data.minutes}{' '}minutes {data.seconds} seconds
                </strong>
              )}
            />
          </div>
        </div>
      </div>
      {bundle.perk_giveaways.length > 0 &&
        !claiming &&
        bundle.perk_giveaways.sort((a, b) => a.event_at_unix - b.event_at_unix).map((perkGiveaway, index) => (
          <PerkGiveaway
            actionResolve={updateOffersOrReferral}
            key={`bundled-perk-giveway-${perkGiveaway.token}`}
            perkGiveaway={perkGiveaway}
            bundle={bundle}
            signUp={signUp}
            around={() => setClaiming((c) => !c)}
            bundleExpired={bundleExpired.includes(bundle.token)}
            lastMinuteAllowed
            last={index === bundle.perk_giveaways.length - 1}
          />
        ))}
      {bundle.perk_giveaways.length > 0 && !error && (
        <button
          onClick={() => onNeedHelp()}
          disabled={requesting}
          className='nothing-works-button'
        >
          Nothing that works right now? Click here and well send you another
          drop in 7 days.
        </button>
      )}
      {bundle.perk_giveaways.length === 0 && (
        <div className="grid-x grid-padding-x grid-padding-y profile-container">
          <div className="small-12 cell float-center ds-container">
            <div
              className="medium-3 large-3"
              style={{ backgroundColor: '#000', padding: '10px' }}
            >
              <h3 className="sub-header" style={{ color: '#fff' }}>
                Ticket Drop
              </h3>
            </div>
            <div
              className="medium-3 large-3"
              style={{ backgroundColor: '#fff', padding: '10px' }}
            >
              {error && (
                <>
                  <p>{error}</p>
                  {!requested && (
                    <button
                      disabled={requesting}
                      onClick={() =>
                        ticketRequest(bundle.short_token, {
                          reason,
                          message: '',
                        })
                      }
                    >
                      MORE TIX PLZ
                    </button>
                  )}
                </>
              )}
              {!activating && !error && (
                <>
                  <strong>It’s time to pick your tickets!</strong>
                  <p>Once we start you'll have 45 minutes to claim.</p>
                  <button
                    disabled={activating}
                    onClick={() => activate(bundle.short_token)}
                  >
                    FIND TICKETS NOW
                  </button>
                </>
              )}
              {activating && !error && (
                <div
                  className="small-12 large-6 cell text-center"
                  style={{ margin: 'auto' }}
                >
                  Finding tickets for you!{' '}
                  <PuffLoader loading={activating} size={25} />
                  <br />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {modalOpen && (
        <BundleModal
          isModalOpen={modalOpen}
          setReason={setReason}
          token={bundle.short_token}
          ticketRequest={ticketRequest}
          setModalOpen={setModalOpen}
        />
      )}
      {!isVisible && bundle.perk_giveaways.length > 0 && !signUp && (
        <div
          className={classNames('form-profile-submit', {
            'floating-countdown': !isVisible,
          })}
        >
          <div style={{ backgroundColor: '#fff' }}>
            <Countdown
              date={moment(bundle.expires_at).toDate()}
              onComplete={() => {
                setBundleExpired((oldBundleExpired) => [
                  ...oldBundleExpired,
                  bundle.token,
                ]);
                setReason('expired');
                setError(
                  "Oh no! Time ran out. We can send you more tix if you'd like? Just click below!"
                );
              }}
              onTick={(data) => {
                if (data.minutes === 5 && data.seconds === 0) {
                  toastr.info('Less than 5 mins left to make your choice!');
                }
              }}
              renderer={(data) => (
                <strong>
                  Tickets will be held for you for the next {data.days > 0 ? `${data.days} days ` : ''}{data.hours > 0 ? `${data.hours} hours ` : ''} {data.minutes}{' '}
                  minutes {data.seconds} seconds
                </strong>
              )}
            />
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default Bundle;
