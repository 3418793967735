import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { lowerCase } from 'lodash';
import { PaymentRequestButtonElement, useStripe } from '@stripe/react-stripe-js';
import { selectOption } from '../selectors';

const PayButton = ({ payButtonSubmit, setSubmitting }) => {
  const stripe = useStripe();
  const option = useSelector(selectOption);
  console.log(option);
  const [paymentRequest, setPaymentRequest] = useState(null);
  const [token, setToken] = useState(null);
  const chargeAmount = parseInt(option.total.replace('$', '').replace('.', ''), 10);
  
  useEffect(() => {
    if (stripe) {
      const pr = stripe.paymentRequest({
        country: option.country || 'US',
        currency: lowerCase(option.currency) || 'usd',
        total: {
          label: 'Total',
          amount: chargeAmount,
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });
      pr.canMakePayment().then(result => {
        if (result) {
          setPaymentRequest(pr);
        }
      });
    }
  }, [stripe]);

  useEffect(() => {
    if (token) {
      payButtonSubmit(token, setSubmitting);
    }
  }, [token]);

  if (paymentRequest) {
    paymentRequest.on('token', (ev) => {
      if (token === null) {
        const { token: { id: t } } = ev;
        setToken(t);
        ev.complete('success');
      }
    });
  }

  if (paymentRequest) {
    return <PaymentRequestButtonElement className="pay-button" options={{ paymentRequest }} />;
  }

  return null;
};

export default PayButton;
