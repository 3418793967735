export const REDEEM = 'REDEEM';
export const CONFIRMATION = 'CONFIRMATION';
export const ERRORS = 'ERRORS';
export const RETRIEVE = 'RETRIEVE';

export const AVAILABLE_METROS = [
  { id: 60, title: 'Austin', waitlist: false },
  { id: 25, title: 'Boston', waitlist: false },
  { id: 3, title: 'Chicago', waitlist: false },
  { id: 11, title: 'Dallas / Ft. Worth', waitlist: false },
  { id: 34, title: 'Denver & Boulder', waitlist: false },
  { id: 64, title: 'Houston', waitlist: false },
  { id: 42, title: 'Indianapolis', waitlist: false },
  { id: 30, title: 'Los Angeles', waitlist: false },
  { id: 47, title: 'Louisville', waitlist: false },
  { id: 24, title: 'Nashville', waitlist: false },
  { id: 10, title: 'New York City', waitlist: false },
  { id: 19, title: 'Portland', waitlist: false },
  { id: 2, title: 'San Antonio', waitlist: false },
  { id: 31, title: 'San Diego', waitlist: false },
  { id: 18, title: 'San Francisco / Bay Area', waitlist: false },
  { id: 9, title: 'Seattle', waitlist: false },
];
