import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get, startCase } from 'lodash';
import { useHistory } from 'react-router-dom';
import { BsClockHistory } from 'react-icons/bs';
import classNames from 'classnames';
import { toastr } from 'react-redux-toastr';
import moment from 'moment';
import ActionButton from './ActionButton';
import { updatePerkGiveaway, displayTransferModal } from './actions';
import {
  selectCustomerStatus,
  selectCustomerToken,
  selectLeadToken,
} from '../Profile/selectors';
import AddToCalendar from './AddToCalendar';
import Truncate from '../Confirmation/Truncate';
import Artists from './Artists';

const PerkGiveaway = ({
  perkGiveaway,
  perk,
  screen,
  past,
  upcoming,
  action,
  actioned,
  actionResolve,
  selectedPerk,
  togglePerk,
  lastMinuteAllowed,
  bundleExpired,
  claimPerk,
  picked,
  show,
  multi,
  around,
  last,
  bundle,
  signUp,
}) => {
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState(null);
  const customerToken = useSelector(selectCustomerToken);
  const customerStatus = useSelector(selectCustomerStatus);
  const leadToken = useSelector(selectLeadToken);
  const customer_token = customerToken || leadToken;
  const logo_url = get(
    perkGiveaway,
    'perk.event_image_url',
    perk?.event_image_url
  );
  const history = useHistory();
  const ticketInfo = get(
    perkGiveaway,
    'perk.event.ticket_info',
    get(perk, 'event.ticket_info', '')
  );

  const updateStatus = (actionName, token) => {
    if (around) {
      around();
    }
    setErrorMessage(null);
    new Promise((resolve, reject) =>
      dispatch(
        updatePerkGiveaway(
          {
            status: actionName,
            token,
            customer_token,
            transfer_first_name: '',
            transfer_last_name: '',
            transfer_email: '',
          },
          resolve,
          reject
        )
      )
    )
      .then((data) => {
        if (around) {
          around();
        }
        if (actionResolve) {
          actionResolve(
            data.templates,
            data.offers,
            data.referral,
            actionName,
            perk,
            perkGiveaway
          );
        }
        if (actionName === 'claim' && !signUp) {
          toastr.success(
            'Success!',
            "The tickets are yours. We'll send you the details shortly.",
            {
              timeOut: 5000,
            }
          );
          if (customerStatus === 'lead') {
            history.push(`/account/${customer_token}`);
          } else {
            history.push(`/tickets/${customerToken}`);
          }
        }
      })
      .catch((e) => {
        if (around) {
          around();
        }
        toastr.error('Oops', e.response.data.message);
        setErrorMessage(e.response.data.message);
      });
  };

  const modal = (actionName, token) =>
    dispatch(displayTransferModal(actionName, token));

  let actions = [];
  const pActions = [
    {
      name: 'claim',
      action: updateStatus,
      show: action === 'claim' || !action,
      tiny: action && action !== 'claim',
    },
    {
      name: 'transfer',
      action: modal,
      show: action === 'transfer' || !action,
      tiny: action && action !== 'transfer',
    },
  ];

  actions = pActions.filter((a) => a.name === 'claim');

  if (action) {
    actions = [
      pActions.find((a) => a.name === action),
      ...pActions.filter((a) => a.name !== action),
    ];
  }

  if (perkGiveaway && perkGiveaway['last_minute?']) {
    actions = pActions.filter((a) => a.name !== 'transfer');
  }

  if (
    perkGiveaway &&
    perkGiveaway.has_vouchers &&
    perkGiveaway.status === 'sent'
  ) {
    actions = pActions.filter((a) => a.name !== 'transfer');
  }

  if (
    perkGiveaway &&
    perkGiveaway.has_vouchers &&
    perkGiveaway.status !== 'sent'
  ) {
    actions = [];
  }

  if (multi) {
    actions = pActions.filter((a) => multi.includes(a.name));
  }

  if (get(perkGiveaway, 'perk.guestlist_sent', perk?.guestlist_sent)) {
    actions = [];
  }

  if (bundle) {
    actions = pActions.filter((a) => a.name === 'claim');
  }

  return (
    <>
      {errorMessage && (
        <div
          className={classNames('grid-x cell', {
            'medium-8 large-8': !show,
            'medium-12 large-12': show,
          })}
          role="button"
          style={{
            color: 'red',
            fontWeight: 'bold',
            textAlign: 'center',
            fontSize: '15px',
          }}
        >
          {errorMessage}
        </div>
      )}
      {!past && (
        <div
          className={classNames('grid-x grid-margin-x ds-offer', {
            'last-minute-decline':
              (screen && screen === 'lmp' && !lastMinuteAllowed) ||
              bundleExpired,
          })}
          style={last ? { paddingBottom: 0 } : {}}
          key={`giveaway-${get(perkGiveaway, 'token', get(perk, 'token', ''))}`}
          onClick={() => {
            if (screen === 'pick_tickets') {
              togglePerk(perk);
            }
          }}
          role="button"
        >
          <div
            className={classNames('grid-x cell card ds-offer-card', {
              'medium-8 large-8': !show,
              'medium-12 large-12': show,
              'ds-offer-card-upcoming':
                upcoming || (perkGiveaway && perkGiveaway.status) === 'sent',
              'bundled-perk':
                perkGiveaway &&
                perkGiveaway.bundled &&
                perkGiveaway.status !== 'claimed',
              picker: perk,
            })}
            role="button"
          >
            {perkGiveaway && upcoming && (
              <span
                className={`label ds-offer-status ds-offer-status-${perkGiveaway.status}`}
              >
                {startCase(perkGiveaway.status)}
              </span>
            )}
            {selectedPerk && selectedPerk.token === perk.token && (
              <span className="label ds-offer-status ds-offer-status-claimed selected">
                {picked ? '' : 'selected'}
              </span>
            )}
            {perkGiveaway && perkGiveaway.last_minute && (
              <span className="ds-offer-lmp" title="Last Minute Tix">
                <BsClockHistory size={20} />
              </span>
            )}
            {logo_url && (
              <div
                className={classNames('cell ds-offer-image', {
                  'medium-4 large-4': !upcoming,
                  'medium-3 large-3': upcoming,
                })}
                style={{ backgroundImage: `url('${logo_url}')` }}
              />
            )}
            <div
              className={classNames('cell ds-offer-details medium-8 large-8')}
            >
              <h4 className="required">
                {get(perkGiveaway, 'perk.name', get(perk, 'name', ''))}
              </h4>
              <p className="ds-date" style={{ marginBottom: 0 }}>
                {perkGiveaway && perkGiveaway.perk.pretty_date_time}
                {perk && perk.pretty_date_time}
              </p>
              <p style={{ marginBottom: 0 }}>
                {ticketInfo && (
                  <>
                    <s style={{ color: '#9CA5B3' }}>{ticketInfo}</s>
                  </>
                )}
              </p>
              <span className="ds-venue">
                <b>
                  {get(
                    perkGiveaway,
                    'perk.event.venue.title',
                    get(perk, 'event.venue.title', '')
                  )}
                </b>
                <a
                  href={`https://maps.google.com/?q=${get(
                    perkGiveaway,
                    'perk.event.venue.title',
                    get(perk, 'event.venue.title', '')
                  )}+${get(
                    perkGiveaway,
                    'perk.event.venue.address',
                    get(perk, 'event.venue.address', '')
                  )}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {' '}
                  - Map
                </a>
              </span>
              {(perk || perkGiveaway) && (
                <span className="ds-detail-description">
                  <Truncate
                    value={get(
                      perkGiveaway,
                      'perk.event.description',
                      get(perk, 'event.description', '')
                    )}
                    num={150}
                  />
                </span>
              )}
              {(perk || (perkGiveaway && perkGiveaway.bundled)) && (
                <>
                  <Artists
                    artists={get(
                      perkGiveaway,
                      'perk.event.bands',
                      get(perk, 'event.bands', [])
                    )}
                    isBundle
                    actions={actions}
                    perk={perk}
                    perkGiveaway={perkGiveaway}
                    bundleExpired={bundleExpired}
                  />
                </>
              )}
              {((screen !== 'lmp' && perk) ||
                (perkGiveaway &&
                  !perkGiveaway.bundled &&
                  (perkGiveaway.status === 'claimed' ||
                    perkGiveaway.status === 'sent'))) && (
                <>
                  <Artists
                    artists={get(
                      perkGiveaway,
                      'perk.event.bands',
                      get(perk, 'event.bands', [])
                    )}
                    isBundle={false}
                    isPicker={['lmp', 'release'].includes(screen)}
                    perkGiveaway={perkGiveaway}
                  />
                </>
              )}
              {perkGiveaway &&
                perkGiveaway.perk.event &&
                !perkGiveaway.bundled && (
                <p className="cell medium-12 large-8 helper-text">
                  {perkGiveaway.status !== 'claimed' && (
                    <span style={{ color: 'red' }}>
                        Claim by:{' '}
                      {moment(perkGiveaway.claim_by)
                        .local()
                        .format('ddd, MMM Do h:mm a')}
                    </span>
                  )}
                </p>
              )}
              {perkGiveaway && !perkGiveaway.bundled && (
                <div className="grid-x ds-offer-details-lower">
                  <div>
                    {perkGiveaway &&
                      perkGiveaway.perk.event !== undefined &&
                      perkGiveaway.status === 'claimed' && (
                      <AddToCalendar perk={perkGiveaway} />
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div
            className={classNames({
              'grid-x cell card ds-offer-card medium-8 large-8 wide-button-wrapper pickerButton': perk,
              'ds-offer-cta cell': perkGiveaway,
              'ds-offer-cta-bundled':
                perkGiveaway &&
                perkGiveaway.bundled &&
                perkGiveaway.status !== 'claimed',
            })}
          >
            {perkGiveaway &&
              perkGiveaway.bundled &&
              perkGiveaway.status !== 'claimed' && (
              <ActionButton
                key={'action-btn-claim'}
                action={updateStatus}
                actionName={'claim'}
                perk={perk}
                token={get(perkGiveaway, 'token', get(perk, 'token', ''))}
                currentStatus={perkGiveaway.status}
                bundleExpired={bundleExpired}
                bundled={perkGiveaway.bundled}
              />
            )}

            {(screen === 'lmp' || screen === 'release') && perk && (
              <div className="grid-x wide-button">
                <ActionButton
                  key={'action-btn-claim'}
                  actionName={'claim'}
                  action={() => claimPerk(perk.token)}
                  perk={perk}
                  token={get(perkGiveaway, 'token', get(perk, 'token', ''))}
                  currentStatus="sent"
                  screen={screen}
                  wideButton
                  lastMinuteAllowed={lastMinuteAllowed}
                />
              </div>
            )}

            {perkGiveaway &&
              perkGiveaway.status !== 'claimed' &&
              !perkGiveaway.bundled && (
              <>
                {actions
                  .filter((a) => a.show)
                  .map((a) => (
                    <ActionButton
                      key={`action-btn-${a.name}`}
                      actionName={a.name}
                      action={a.action}
                      perk={perk}
                      token={get(
                        perkGiveaway,
                        'token',
                        get(perk, 'token', '')
                      )}
                      currentStatus={perkGiveaway.status}
                      bundleExpired={bundleExpired}
                      bundled={perkGiveaway.bundled}
                    />
                  ))}
              </>
            )}

            {!actioned && perkGiveaway && perkGiveaway.status === 'claimed' && (
              <>
                {actions
                  .filter((a) => a.show)
                  .map((a) => (
                    <ActionButton
                      key={`action-btn-${a.name}`}
                      actionName={a.name}
                      action={a.action}
                      perk={perk}
                      token={get(perkGiveaway, 'token', get(perk, 'token', ''))}
                      currentStatus={perkGiveaway.status}
                      bundleExpired={bundleExpired}
                      bundled={perkGiveaway.bundled}
                    />
                  ))}
                {show && (
                  <p
                    className={classNames({
                      'bit-of-margin': action === 'release' && show,
                    })}
                  >
                    <a role="button" onClick={() => history.goBack()}>
                      Back
                    </a>
                  </p>
                )}
              </>
            )}
          </div>
        </div>
      )}
      {past && (
        <div className="cell small-12 medium-6">
          <div className="card ds-offer-card ds-offer-card-past grid-x">
            <span
              className={`label ds-offer-status ds-offer-status-${
                perkGiveaway.status === 'sent' ? 'expired' : perkGiveaway.status
              }`}
            >
              {perkGiveaway.status === 'sent'
                ? 'Expired'
                : startCase(perkGiveaway.status)}
            </span>
            {perkGiveaway.last_minute && (
              <span className="ds-offer-lmp" title="Last Minute Tix">
                <BsClockHistory size={20} />
              </span>
            )}
            {logo_url && (
              <div
                className="cell small-4 ds-offer-image"
                style={{ backgroundImage: `url('${logo_url}')` }}
              />
            )}
            <div className="cell small-8 ds-offer-details">
              <h5 className="ds-offer-details-title">
                {perkGiveaway.perk.name}
              </h5>
              <div className="grid-x ds-offer-details-lower">
                {perkGiveaway.perk.event && (
                  <p className="cell small-12 helper-text">
                    <span className="ds-venue">
                      {perkGiveaway.perk.event?.venue.title ?? ''}
                    </span>{' '}
                    <span className="ds-date">
                      {moment(perkGiveaway.perk.event?.begin_time_in_tz).format(
                        'ddd, MMM Do YYYY'
                      ) ?? moment().format('ddd, MMM Do YYYY')}
                    </span>
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default PerkGiveaway;
