import React, { useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { ProgressBar, Step } from 'react-step-progress-bar';
import InputMask from 'react-input-mask';
import { BiCheck } from 'react-icons/bi';
import { GrClose } from 'react-icons/gr';
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';
import { toastr } from 'react-redux-toastr';
import { useForm } from 'react-hook-form';
import { omit } from 'lodash';
import classNames from 'classnames';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import {
  selectSteps,
  selectExperiences,
  selectGenres,
  selectNeighborhoodGeoJson,
  selectSupportEmail,
  selectPartner,
  selectPartnerSteps,
  selectMetroId,
} from '../../../helpers/metro/selectors';
import {
  selectIsFreeTrial,
  selectPerkReferral,
  selectOption,
  selectLeadToken,
  selectLead,
  selectOptin,
} from '../selectors';
import CheckboxGroup from '../../../helpers/form/CheckboxGroup';
import Register from './Register';
import PickTickets from './PickTickets';
import {
  saveLiteProfile,
  setCustomerAndProfile,
  setSmsOptin,
  storeLead,
  subscribe,
  trackLead,
} from '../actions';
import { claim } from '../../Confirmation/actions';
import { GENRE_MAPPING, EMAIL_REGEX, PHONE_NUMBER_REGEX } from '../constants';
import Referral from './Referral';
import Landing from './Landing';
import CityPicker from './CityPicker';
import BundlePicker from './BundlePicker';
import { perks as mockPerks } from './mock';

function getUtmParams() {
  const { search } = useLocation();

  const utmParams = ['campaign', 'medium', 'source', 'name'];
  const utms = {};
  const utmSearchParams = new URLSearchParams(search);
  utmParams.forEach((utm) => {
    if (
      utmSearchParams.get(`utm_${utm}`) !== '' &&
      utmSearchParams.get(`utm_${utm}`) !== null
    ) {
      utms[utm] = utmSearchParams.get(`utm_${utm}`);
    }
  });

  return useMemo(() => utms, [search]);
}

export const Stepper = ({ newPath }) => {
  const { kiosk, partner_token, referral_code, plan_name, partner_name } =
    useParams();

  const { search } = useLocation();
  const utmSearchParams = new URLSearchParams(search);
  const ticket_dispatch_id = utmSearchParams.get('d');
  const partner = useSelector(selectPartner);
  const option = useSelector(selectOption);
  const perkReferral = useSelector(selectPerkReferral);
  const supportEmail = useSelector(selectSupportEmail);
  const defaultSteps = useSelector(selectSteps);
  const partnerSteps = useSelector(selectPartnerSteps);
  const metro_id = useSelector(selectMetroId);
  const lead = useSelector(selectLead);
  const steps = newPath ? partnerSteps : defaultSteps;
  const experiences = useSelector(selectExperiences);
  const genres = useSelector(selectGenres);
  const isFreeTrial = useSelector(selectIsFreeTrial);
  const neighborhoods = useSelector(selectNeighborhoodGeoJson);
  const leadToken = useSelector(selectLeadToken);
  const selectedOptin = useSelector(selectOptin);
  const [perks, setPerks] = useState([]);
  const [perk, setPerk] = useState(null);
  const [customerToken, setCustomerToken] = useState();
  const [submitted, setSubmitted] = useState(false);
  const [savingProfile, setSavingProfile] = useState(false);
  const [picking, setPicking] = useState(false);
  const [picked, setPicked] = useState(false);
  const [newPathPicked, setNewPathPicked] = useState(false);
  const [rescinded, setRescinded] = useState([]);
  const [contactErrors, setContactErrors] = useState(null);
  const checkboxStyles = {
    width: '18px',
    height: '18px',
    margin: 0,
    marginRight: '5px',
  };
  const [showLanding, setShowLanding] = useState(
    Boolean(utmSearchParams.get('hl'))
  );
  const [stripeError, setStripeError] = useState(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const dispatchGTM = useGTMDispatch();
  const utmParams = getUtmParams();

  const testing = utmSearchParams.get('testing');
  const optionName = utmSearchParams.get('on') || plan_name || 'monthly';

  const option_name = optionName || option_name;

  let defaultStep = 0;
  if (window.location.search.match(/testing=[0-9]/)) {
    defaultStep = parseInt(
      window.location.search.match(/testing=([0-9])/)[1],
      10
    );
  }

  const stripe = useStripe();

  const defaultStepProgressPercentages = [5, 16.5, 35, 45, 55, 67, 67, 100];

  const partnerStepProgressPercentages = [5, 16.5, 35, 50, 60, 67, 100, 100];

  const stepProgressPercentages = newPath
    ? partnerStepProgressPercentages
    : defaultStepProgressPercentages;

  const [initialFirstName, initialLastName] = (
    utmSearchParams.get('name') || ''
  ).split(' ');
  const initialEmail = (utmSearchParams.get('email') || '').split(' ');
  const initialData = {
    first_name: initialFirstName,
    last_name: initialLastName,
    email: initialEmail,
    phone_number: '',
    plan_option: option?.name || 'monthly',
    genres: kiosk ? genres.map((e) => ({ name: e })) : [],
    experiences: kiosk ? experiences.map((e) => ({ name: e })) : [],
    neighborhoods: [],
  };
  const [currentStep, setCurrentStep] = useState(defaultStep);
  const { register, control, trigger, errors, getValues, setError } = useForm({
    defaultValues: initialData,
  });

  const validate = async (step) => {
    if (step.field === 'name') {
      const firstNameCheck = await trigger('first_name');
      const lastNameCheck = await trigger('last_name');
      return firstNameCheck && lastNameCheck;
    }
    if (step.field === 'email' || step.field === 'contact') {
      const phoneNumberCheck = await trigger('phone_number');
      const emailCheck = await trigger('email');
      window.gtag('event', 'submit_email', {
        app_name: 'member_portal',
        screen_name: 'Email',
      });
      return phoneNumberCheck && emailCheck;
    }

    const check = await trigger(step.field);
    return check;
  };

  const move = (direction, step) => {
    let nextStep = currentStep;
    const values = getValues();
    if (
      direction === 'next' &&
      step.field === 'neighborhoods' &&
      values.neighborhoods.filter((g) => g.name).length === 0 &&
      step.required
    ) {
      setError('neighborhoods', {
        type: 'manual',
        message: 'Please select at least 1 neighborhood!',
      });
      return;
    }
    if (
      direction === 'next' &&
      step.field === 'genres' &&
      values.genres.filter((g) => g.name).length === 0 &&
      step.required
    ) {
      setError('genres', {
        type: 'manual',
        message: 'Please select at least 1 genre!',
      });
      return;
    }
    if (
      direction === 'next' &&
      step.field === 'experiences' &&
      values.experiences.filter((g) => g.name).length === 0 &&
      step.required
    ) {
      setError('experiences', {
        type: 'manual',
        message: 'Please select at least 1 experience!',
      });
      return;
    }
    if (
      direction === 'next' &&
      (step.field === 'email' || step.field === 'contact')
    ) {
      const thisStep = currentStep;
      if (!lead) {
        const p = new Promise((resolve, reject) => {
          dispatch(trackLead(values, utmParams, resolve, reject));
        });
        p.then(() => {
          dispatch(storeLead(values));
        }).catch((res) => {
          setContactErrors(res.response.data.errors);
          setCurrentStep(thisStep);
        });
      }
    }
    if (direction === 'next' && currentStep < steps.length - 1) {
      nextStep = currentStep + 1;
    }
    if (direction === 'prev' && currentStep > 0) {
      nextStep = currentStep - 1;
    }
    if ((kiosk || perkReferral) && nextStep === 2) {
      nextStep = 6;
    }
    if ((kiosk || perkReferral) && nextStep === 5) {
      nextStep = 1;
    }
    setCurrentStep(nextStep);
  };

  const moveStep = (direction) => {
    const step = steps[currentStep];
    if (direction === 'next' && step.type === 'text') {
      validate(step).then((res) => {
        if (res) {
          move(direction, step);
        }
      });
    } else {
      move(direction, step);
    }
  };

  const setDisplay = (field) => steps[currentStep].field === field;

  const { first_name, last_name, email } = getValues();
  const name = [first_name, last_name].join(' ');

  const elements = useElements();

  const stripeRequest = async (setSubmitting) => {
    setSubmitting(true);
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardElement);

    // Use your card Element with other Stripe.js APIs
    const { error, token } = await stripe.createToken(cardElement);

    if (error) {
      setSubmitting(false);
      if (error.code === 'incomplete_zip') {
        setStripeError('Zipcode is required!');
      } else {
        setStripeError(
          `There was a problem with your card.  Please try again.  If the problem persists contact ${supportEmail}`
        );
      }
    } else {
      doSubscribe(token.id, setSubmitting);
    }
  };

  const payButtonSubmit = (token, setSubmitting) =>
    doSubscribe(token, setSubmitting);

  window.getvalues = getValues();
  window.genre_mapping = GENRE_MAPPING;

  const doSubscribe = (token, setSubmitting) => {
    new Promise((resolve, reject) => {
      setSubmitting(true);
      setStripeError(null);
      const getVs = omit(
        {
          ...getValues(),
          genre_list: GENRE_MAPPING.filter((g) =>
            getValues()
              .genres.map((s) => s.name)
              .includes(g.label)
          )
            .map((selected) => selected.genres)
            .flat()
            .map((g) => g.replaceAll(' ', '-')),
        },
        ['genres']
      );
      dispatch(
        subscribe(
          {
            utm: utmParams,
            stripe_token: token,
            name,
            ...getVs,
            neighborhoods: getValues()
              .neighborhoods.filter((n) => n.name)
              .map((s) => s.name),
            referral_code: partner.referral_code,
            customer_referral_code: referral_code,
            metro_id,
          },
          resolve,
          reject
        )
      );
    })
      .then((payload) => {
        const {
          data: {
            customer: { customer, profile },
            perks: ps,
            tracking,
          },
        } = payload;
        console.log('payload: ', payload);
        console.log('customer, profile: ', customer, profile);
        const product = tracking.transactionProducts[0];
        window.gtag('event', 'purchase', {
          transaction_id: tracking.transactionId,
          value: tracking.transactionTotal,
          tax: 0,
          shipping: 0,
          currency: 'USD',
          items: [
            {
              item_id: product.sku,
              item_name: `${product.name} ${product.variation}`,
              affiliation: 'DoMORE',
              discount: 0,
              index: 0,
              item_brand: product.name,
              item_category: product.category,
              price: product.price,
              quantity: product.quantity,
            },
          ],
        });
        dispatchGTM(tracking);
        dispatch(setCustomerAndProfile(customer, profile));
        setCustomerToken(customer.token);
        if (!perkReferral) {
          setPerks(ps);
        }
        if (newPath) {
          setSubmitting(false);
        }
        moveStep('next');
      })
      .catch(
        ({
          response: {
            data: { message },
          },
        }) => {
          setSubmitting(false);
          setStripeError(message);
        }
      );
  };

  const saveProfile = () => {
    setSavingProfile(true);
    const vs = {
      experiences: getValues()
        .experiences.filter((e) => e.name)
        .map((e) => e.name),
      neighborhoods: getValues()
        .neighborhoods.filter((n) => n.name)
        .map((s) => s.name),
      genre_list: GENRE_MAPPING.filter((g) =>
        getValues()
          .genres.map((s) => s.name)
          .includes(g.label)
      )
        .map((selected) => selected.genres)
        .flat()
        .map((g) => g.replaceAll(' ', '-')),
    };
    new Promise((resolve, reject) => {
      dispatch(saveLiteProfile(vs, leadToken, resolve, reject));
    }).then(() => {
      setTimeout(() => {
        history.push(`/${newPathPicked ? 'tickets' : 'offers'}/${leadToken}`);
      }, 2000);
    });
  };

  const claimPerk = (tok) => {
    new Promise((resolve, reject) => {
      setPicking(true);
      dispatch(
        claim(
          customerToken,
          tok,
          null,
          partner_token ? 'partner' : 'register',
          resolve,
          reject
        )
      );
    })
      .then(() => {
        setSubmitted(true);
        if (kiosk) {
          setPicking(false);
          setPicked(true);
        } else {
          setTimeout(
            () => history.push(`/profile/${customerToken}/claimed`),
            2000
          );
        }
      })
      .catch((e) => {
        setRescinded([...rescinded, tok]);
        setPicking(false);
        setSubmitted(false);
        toastr.error('Oops', e.response.data.message);
      });
  };

  const togglePerk = (p) => {
    if (perk && p.token === perk.token) {
      setPerk(null);
    } else {
      setPerk(p);
    }
  };

  const onOptinChange = (e) => {
    dispatch(setSmsOptin(e.target.checked));
  };

  if (steps === undefined) {
    return null;
  }

  if (!option) {
    return (
      <CityPicker
        option_name={option_name}
        defaultCity={utmSearchParams.get('city') || null}
      />
    );
  }

  if (option.top_block !== '' && showLanding && !newPath) {
    return (
      <Landing
        history={history}
        setShowLanding={setShowLanding}
        partner={partner}
        option={option}
      />
    );
  }

  if (showLanding && newPath) {
    return (
      <Landing
        newPath={newPath}
        setShowLanding={setShowLanding}
        partner={partner}
        history={history}
      />
    );
  }

  return (
    <div className="grid-x grid-padding-x grid-padding-y profile-container">
      <div
        role="button"
        className="cancel-stepper"
        onClick={() => history.goBack()}
      >
        <GrClose />
      </div>
      <div
        className={classNames('small-11 cell float-center ds-container', {
          'stepper-container': currentStep < 7,
        })}
      >
        <ProgressBar
          percent={stepProgressPercentages[currentStep]}
          hasStepZero
          filledBackground="linear-gradient(to right, #4DB81A, #4DB81A)"
        >
          <Step>
            {() => (
              <div
                className={classNames('indexedStep', {
                  accomplished: currentStep > 0,
                  active: currentStep >= 0 && currentStep < 5,
                })}
              >
                <button className="stepper-step">
                  {currentStep > 1 ? <BiCheck /> : '1'}
                </button>
                <div className="label">Start</div>
              </div>
            )}
          </Step>
          {}
          <Step>
            {({ accomplished }) => (
              <div
                className={classNames('indexedStep', {
                  accomplished,
                  active: currentStep >= 2 && currentStep < 5,
                })}
              >
                <button className="stepper-step">
                  {currentStep >= 5 ? <BiCheck /> : '2'}
                </button>
                <div className="label">
                  {!perkReferral && newPath ? 'Preferences' : 'Pick Tickets'}
                  {perkReferral && !newPath ? 'Tickets' : null}
                </div>
              </div>
            )}
          </Step>
          <Step>
            {({ accomplished }) => (
              <div
                className={`indexedStep ${accomplished ? 'accomplished' : ''}`}
              >
                <button className="stepper-step">
                  {currentStep >= 7 ? <BiCheck /> : '3'}
                </button>
                <div className="label">
                  {newPath ? 'Sign Up' : 'Preferences'}
                </div>
              </div>
            )}
          </Step>
          <Step>
            {({ accomplished }) => (
              <div
                className={`indexedStep ${accomplished ? 'accomplished' : ''}`}
              >
                <button className="stepper-step">
                  {currentStep > 7 || submitted ? <BiCheck /> : '4'}
                </button>
                <div className="label">Pick Tickets</div>
              </div>
            )}
          </Step>
        </ProgressBar>
        <section
          className="ds-stepper-section ds-stepper-section-name"
          style={{ display: `${setDisplay('name') ? '' : 'none'}` }}
        >
          <h4 className="required has-helper-text" style={{ fontSize: '28px' }}>
            What is your name?
          </h4>
          <fieldset className="form-row no-bg user-info">
            <label htmlFor="first_name">
              <input
                name="first_name"
                ref={register({ required: 'First name is required' })}
                placeholder="Enter your first name"
                className={classNames({
                  'input-field-error': errors && errors.first_name,
                })}
              />
            </label>
            <label htmlFor="last_name">
              <input
                name="last_name"
                ref={register({ required: 'Last name is required' })}
                placeholder="Enter your last name"
                className={classNames({
                  'input-field-error': errors && errors.last_name,
                })}
              />
            </label>
            <input
              type="hidden"
              name="plan_option"
              ref={register({ required: true })}
              value={option.name || 'monthly'}
            />
          </fieldset>
          <span>
            First and Last name please. So we can add it to guestlists.
          </span>
          {errors && (errors.first_name || errors.last_name) && (
            <div className="ra-field-error">
              First and Last name are required!
            </div>
          )}
        </section>
        <section
          className="ds-stepper-section ds-stepper-section-email"
          style={{
            display: `${
              setDisplay('email') || setDisplay('contact') ? '' : 'none'
            }`,
          }}
        >
          <h4 className="required has-helper-text" style={{ fontSize: '28px' }}>
            {setDisplay('contact')
              ? 'Where should we send your tickets?'
              : 'What is your email?'}
          </h4>
          <fieldset className="form-row no-bg user-info">
            <label htmlFor="email">
              <input
                name="email"
                ref={register({
                  required: 'Required',
                  pattern: {
                    value: EMAIL_REGEX,
                    message: 'invalid email address',
                  },
                })}
                placeholder="Enter your email"
                className={classNames({
                  'input-field-error': errors && errors.email,
                })}
              />
            </label>
            <label
              htmlFor="phone_number"
              style={{ display: setDisplay('contact') ? '' : 'none' }}
            >
              <InputMask
                mask="(999) 999-9999"
                name="phone_number"
                placeholder="Enter your phone number"
                ref={register({
                  required: setDisplay('contact'),
                  pattern: {
                    value: PHONE_NUMBER_REGEX,
                    message: 'invalid phone number',
                  },
                })}
                className={classNames({
                  'input-field-error': errors && errors.phone_number,
                })}
              />
            </label>
          </fieldset>
          {!setDisplay('contact') && (
            <span>We'll send you your tickets here</span>
          )}
          {errors && (errors.email || errors.phone_number) && (
            <div className="ra-field-error">
              {setDisplay('contact')
                ? 'Email & phone number are required!'
                : 'Email is required!'}
            </div>
          )}
          {contactErrors && (
            <div className="ra-field-error" style={{ marginBottom: '5px' }}>
              {contactErrors}
            </div>
          )}
          <div
            className="small-12 cell float-center ds-container"
            style={{
              display: setDisplay('contact') ? '' : 'none',
              textAlign: 'center',
            }}
          >
            <input
              style={checkboxStyles}
              type="checkbox"
              defaultChecked={selectedOptin}
              onChange={onOptinChange}
              id="optin"
            />
            <label htmlFor="optin">
              {' '}
              <span>
                I want to get my ticket offers via text messages. So they don't
                get lost in email.
              </span>
            </label>
            {selectedOptin && (
              <div style={{ marginTop: '10px' }}>
                <span style={{ fontSize: '10px' }}>
                  Expect up to 6 texts/ month. Message and data rates may apply.
                  Text STOP to cancel. HELP for help. See our{' '}
                  <a
                    href="https://dostuffmedia.com/privacy-policy"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    privacy policy
                  </a>{' '}
                  for details
                </span>
              </div>
            )}
          </div>
        </section>
        <section
          className="ds-stepper-section ds-stepper-section-experiences"
          style={{ display: `${setDisplay('experiences') ? '' : 'none'}` }}
        >
          <CheckboxGroup
            items={experiences}
            fieldName="experiences"
            register={register}
            control={control}
            getValues={getValues}
            columns
            questionText="Which of the following types of experiences are you interested in?"
          />
          {errors && errors.experiences && (
            <div className="ra-field-error">
              Please select at least 1 experience!
            </div>
          )}
        </section>
        <div style={{ display: `${setDisplay('bundle') ? '' : 'none'}` }}>
          {newPath && (
            <BundlePicker
              ticket_dispatch_id={ticket_dispatch_id}
              partner_name={partner_name}
              moveStep={moveStep}
              setNewPathPicked={setNewPathPicked}
              currentStep={steps[currentStep]}
            />
          )}
        </div>
        <section
          className="ds-stepper-section ds-stepper-section-genres"
          style={{ display: `${setDisplay('genres') ? '' : 'none'}` }}
        >
          <CheckboxGroup
            items={genres}
            getValues={getValues}
            fieldName="genres"
            register={register}
            control={control}
            columns
            questionText="Which of the following genres of music are you interested in?"
          />
          {errors && errors.genres && (
            <div className="ra-field-error">
              Please select at least 1 genre!
            </div>
          )}
        </section>
        <section
          className="ds-stepper-section ds-stepper-section-neighborhoods"
          style={{ display: `${setDisplay('neighborhoods') ? '' : 'none'}` }}
        >
          <CheckboxGroup
            items={neighborhoods}
            getValues={getValues}
            fieldName="neighborhoods"
            register={register}
            control={control}
            columns
            questionText="Which of the following neighborhoods are you interested in?"
          />
          {errors && errors.neighborhoods && (
            <div className="ra-field-error">
              Please select at least 1 neighborhood!
            </div>
          )}
        </section>
        <section
          className="ds-stepper-section ds-stepper-section-found_tickets"
          style={{ display: `${setDisplay('found_tickets') ? '' : 'none'}` }}
        >
          <p>
            You're in! You matched with 3 events.{' '}
            {isFreeTrial ? 'Start your trial' : 'Sign up'} now and pick your
            tickets.
          </p>
          <button type="button" onClick={() => moveStep('next')}>
            {isFreeTrial ? 'Start Trial' : 'Sign Up'}
          </button>
        </section>
        <section
          className="ds-stepper-section ds-stepper-section-sign_up"
          style={{ display: `${setDisplay('sign_up') ? '' : 'none'}` }}
        >
          <Register
            cardElement={<CardElement id="card-element" className="field" />}
            getValues={getValues}
            stripeRequest={stripeRequest}
            isFreeTrial={isFreeTrial}
            payButtonSubmit={payButtonSubmit}
            stripeError={stripeError}
            name={name}
            email={email}
            newPath={newPath}
            newPathPicked={newPathPicked}
            option={option}
          />
        </section>
        <section
          className="ds-stepper-section ds-stepper-section-pick_tickets"
          style={{ display: `${setDisplay('pick_tickets') ? '' : 'none'}` }}
        >
          {!perkReferral && (
            <PickTickets
              picked={picked}
              picking={picking}
              setDisplay={setDisplay}
              perks={
                testing
                  ? mockPerks.records
                  : perks.filter((p) => !rescinded.includes(p.token))
              }
              claimPerk={claimPerk}
              selectedPerk={perk}
              togglePerk={togglePerk}
              partner_token={partner_token}
            />
          )}
          {perkReferral && (
            <Referral
              perkReferral={perkReferral}
              confirmation
              customerToken={customerToken}
              referral_code={referral_code}
            />
          )}
        </section>
        <section style={{ display: `${perkReferral ? '' : 'none'}` }}>
          {!customerToken && perkReferral && (
            <Referral
              perkReferral={perkReferral}
              referral_code={referral_code}
            />
          )}
        </section>
        <section>
          <div className="grid-x grid-padding-x form-profile-submit">
            {!picked && (
              <div className="small-6 large-3 cell float-center">
                {currentStep > 0 && currentStep !== 7 && (
                  <button
                    style={{ display: 'inline' }}
                    type="button"
                    onClick={() => moveStep('prev')}
                    disabled={savingProfile}
                  >
                    Prev
                  </button>
                )}
              </div>
            )}
            <div className="small-6 large-3 cell float-center">
              {picked}
              {!picked &&
                currentStep < steps.length - 1 &&
                currentStep !== 6 && (
                <button
                  className={`ds-next-button ds-next-button-${steps[currentStep].field} `}
                  type="button"
                  onClick={() => moveStep('next')}
                  style={{
                    display:
                      newPath &&
                      (steps[currentStep].field === 'sign_up' ||
                        steps[currentStep].field === 'bundle')
                        ? 'none'
                        : 'inline',
                  }}
                >
                  Next
                </button>
              )}
              {!picked && currentStep === 7 && perk && (
                <button type="button" onClick={() => claimPerk(perk.token)}>
                  {picking ? 'Claiming...' : 'Claim'}
                </button>
              )}
              {!picked && currentStep === 7 && !perk && (
                <button
                  type="button"
                  onClick={() =>
                    history.push(`/profile/${customerToken}/unclaimed`)
                  }
                  style={{
                    display:
                      newPath &&
                      (steps[currentStep].field === 'sign_up' ||
                        steps[currentStep].field === 'bundle')
                        ? 'none'
                        : 'inline',
                  }}
                >
                  Next
                </button>
              )}
              {newPath && currentStep === partnerSteps.length - 1 && (
                <button
                  type="button"
                  onClick={() => saveProfile()}
                  disabled={savingProfile}
                  style={{
                    display:
                      newPath &&
                      (steps[currentStep].field === 'sign_up' ||
                        steps[currentStep].field === 'bundle')
                        ? 'none'
                        : 'inline',
                  }}
                >
                  {savingProfile ? 'Saving...' : 'Finish'}
                </button>
              )}
              {picked && (
                <button type="button" onClick={() => history.go(0)}>
                  Start Again
                </button>
              )}
            </div>
          </div>
        </section>
        <section />
      </div>
    </div>
  );
};
