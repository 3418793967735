import React from 'react';
import { useSelector } from 'react-redux';
import { selectMetroSettings } from '../../helpers/metro/selectors';
import Navigation from './Navigation';
import SubscriptionStatus from './SubscriptionStatus';

const Header = ({ noNav, doMoreLogo }) => {
  const metroSettings = useSelector(selectMetroSettings);
  const { city_name, more_logo_url } = metroSettings;

  return (
    <>
      <div className="grid-x grid-padding-x grid-padding-y">
        <div className="small-12 cell float-center ds-container">
          <img
            src={doMoreLogo ? 'https://dvr1tqe2n5gnv.cloudfront.net/DoMORE_DarkText.png' : more_logo_url}
            alt={`${noNav ? 'DoMORE Logo' : `DoMORE ${city_name}`}`}
            className='ds-more-logo'
          />
        </div>
      </div>
      {!noNav && <Navigation />}
      {!noNav && <SubscriptionStatus />}
    </>
  );
};

export default Header;
